import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import {Colors} from './../../utils/variables';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="primary-background" style={{backgroundColor:Colors.primaryDark,backgroundImage:'url("/images/topo-texture.png")'}}>
      <div className="large-padding centered-text">
        <div className="max-width-container" style={{maxWidth:1200,marginTop:80}}>
          <h2 style={{color:'#fff'}}>{'Registration'}</h2>
          <div className="row">
            <div className="small-12 medium-12 tiny-padding">
              <div className="card primary-background" style={{backgroundSize:'cover', backgroundPosition:'center',backgroundImage:'url("/images/about-us-collage-2.jpg")', borderRadius:10, padding:30, marginTop:30}}>
                <div className="small-max-width-container" style={{maxWidth:400}}>
                  <a style={{textDecoration:'none'}} target="_blank" href="https://square.link/u/C78ECV0J">
                    <div className="card primary-background" style={{ borderRadius:10, padding:30}}>
                      <img className="full-width" src="./images/free-to-roam.png"/>
                      <h2 className="white" style={{textDecoration:'none'}}>{'Early Bird Registration'}</h2>
                      <h4 className="white" style={{textDecoration:'none'}}>{'September 19-21, 2025'}</h4>
                      <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Register Now</div>
                      <h6 className="white subheading">{'Early bird deal ends 3/18/25. Space limited to 15 people, early registration is recommended'}</h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-width-container" style={{maxWidth:1200,marginTop:80}}>
          <h2 className="white">{'A SINGLETRACK MIND, MTB SKILS CLINICS'}</h2>
          <h4 className="white">{'$65 per person, per clinic.'}</h4>
          <p className="subheading white">{'With a mental skills coaching certification with NASM, highest level of certifications through BICP 1,2,3, USA Cycling, NICA and NICA coaches trainer and the head coach for Betterride (pioneer of mtb skills coaching),  Dylan Renn truly ties the technical details to riding with the mental component to riding. Dylan has over 25 years of mountain bike racing experience both at the expert and professional level and 14 years as a professional coach. Clinics are first come, first serve and must be registered for in advance. Each clinic is limited to 20 riders max.'}</p>
          <div className="row" style={{alignItems:'stretch'}}>
            <div className="small-12 medium-6 tiny-padding">
              <a className="full-height" style={{textDecoration:'none'}} target="_blank" href="https://asingletrackmind.com/event/mtb-core-fundamentals-peakpursuits-womens-retreat">
                <div className="card primary-background" style={{ borderRadius:10, padding:30, height:'100%'}}>
                  <h2 className="white" style={{textDecoration:'none'}}>{'MTB CORE FUNDAMENTALS'}</h2>
                  <h4 className="white" style={{textDecoration:'none'}}>{'9/20, 9:30am-12pm'}</h4>
                  <p className="white subheading">{'Learn solid foundation skills.  ex: cornering, braking, slow speed, balance, body position, wheel lifts, variance of the skills, switchbacks, basic drops, vision, and more. Our Core fundamental clinics are for all abilities. Suited for those that are eager to refine the skill set they presently have- to the competitive, even professional athlete eager to get to the top of their game. We help you build, refine and expand your present riding skill set.The clinic is progression based skill building. Meaning, that there are progressions for each skill. So that each level of rider can progress and challenge within their own ability.'}</p>
                  <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Register Now</div>
                </div>
              </a>
            </div>
            <div className="small-12 medium-6 tiny-padding">
              <a className="full-height" style={{textDecoration:'none'}} target="_blank" href="https://asingletrackmind.com/event/mtb-cornering-switchbacks-peak-pursuits-womens-retreat-2">
                <div className="card primary-background" style={{ borderRadius:10, padding:30, height:'100%'}}>
                  <h2 className="white" style={{textDecoration:'none'}}>{'MTB CORNERING/SWITCHBACKS'}</h2>
                  <h4 className="white" style={{textDecoration:'none'}}>{'9/20, 1-3:30pm'}</h4>
                  <p className="white subheading">{'Skills clinic focusing on the Fundamentals and purpose of cornering. Plan on looking at reasons to lift the front or rear wheel, where to use it on trail and how it can make you faster, smoother and more confident on the bike and trail. Learning proper cornering technique and principles will improve your over all ride. With that new speed and confidence you’ll  need to be able to corner faster. A great opportunity to expand the techniques you may already be using.'}</p>
                  <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Register Now</div>
                </div>
              </a>
            </div>
            <div className="small-12 medium-6 tiny-padding">
              <a className="full-height" style={{textDecoration:'none'}} target="_blank" href="https://asingletrackmind.com/event/mtb-wheel-lifts-technical-terrain">
                <div className="card primary-background" style={{ borderRadius:10, padding:30, height:'100%'}}>
                  <h2 className="white" style={{textDecoration:'none'}}>{'MTB WHEEL LIFTS/TECHNICAL TERRAIN'}</h2>
                  <h4 className="white" style={{textDecoration:'none'}}>{'9/21, 9:30am-12pm'}</h4>
                  <p className="white subheading">{'Understanding technical terrain demands quick responses. Learning how to weight and unweight your bike will make you faster in uneven terrain.   Emphasis on the progression in technique with more advanced maneuvers of the skills learned from Core Fundamentals.  ex: cornering, braking, slow speed, balance, body position, wheel lifts, variance of the skills, switchbacks, basic drops, vision, and more. In addition to the technical skills we will also spend time discussing scouting the line, vision and ways to stay committed to the bike and trail in challenging terrain. A flow riding component is often added onto this clinic.'}</p>
                  <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Register Now</div>
                </div>
              </a>
            </div>
            <div className="small-12 medium-6 tiny-padding">
              <div className="full-height card primary-background" style={{ borderRadius:10, padding:30, height:'100%'}}>
                <h2 className="white" style={{textDecoration:'none'}}>{'YOGA'}</h2>
                <h4 className="white" style={{textDecoration:'none'}}>{'7:30-8:15am Saturday (9/20) and Sunday (9/21)'}</h4>
                <p className="white subheading">{'Besides coordinating mtb clinics, Amy’s foundation is fitness and wellness for over 25 years with an emphasis on functional outdoor fitness. She is a certified personal and group fitness trainer, yoga instructor, certified outdoor fitness and TRX instructor, with her Masters degree in Kinesiology-sport performance. She is also a certified massage therapist since 1999 and is the co-owner of A Singletrack Mind-mountain bike skills coaching. When she is not coordinating mountain bike skills clinics or working with clients to better their health, she is out on the trail running, skiing, biking or exploring the great outdoors.'}</p>
                <p className="white"><b>{'Chill Mountain Yoga before breakfast and bikes. Please bring your own mat, all levels welcome. Donation Based, no registration required.'}</b></p>

              </div>
            </div>
          </div>
        </div>
        <div className="max-width-container" style={{maxWidth:1200,marginTop:80}}>
          <h2 style={{color:'#fff'}}>{'Merch'}</h2>
          <p className="subheading" style={{color:'#fff'}}>{`Being rad comes with a first step of looking fresh`}</p>
          <a style={{textDecoration:'none'}} target="_blank" href=" https://square.link/u/aFDuVMYh?src=sheet">
            <div className="card primary-background" style={{borderRadius:10, padding:30}}>
              <div className="row">
                <div className="small-12 medium-6">
                  <img className="full-width" src="/images/shirt.jpg" />
                </div>
                <div className="small-12 medium-6 small-padding row" style={{alignItems:'center'}}>
                  <div className="full-width">
                  <h2 className="white" style={{textDecoration:'none'}}>{'Peak Pursuits Tank'}</h2>
                  <div style={{marginBottom:20,backgroundColor:Colors.tertiary, color:Colors.white}} className="button">Buy Now</div>
                  </div>
                </div>
              </div>
            </div>
          </a>

        </div>
        <div className="max-width-container" style={{maxWidth:1200,marginTop:80}}>
          <h2 style={{color:'#fff'}}>{'Kirkwood Lodging Info'}</h2>
          <div className="card primary-background" style={{borderRadius:10, padding:30}}>
            <h2 className="white" style={{textDecoration:'none'}}>{'Stay With Us'}</h2>
            <p className="white subheading">{`In order to receive the Peak Pursuits special group rate please call (855) 948-0694. This is a reservation line dedicated to making reservations for group guests. Guests can call every day from 8am-5pm MT. Please refer to the group code: `}<b>{`SKWPPB25`}</b>{` or the group name:  Peak Pursuits 25 when making your reservation. The nightly set rates for lodging at the Mountain Club at Kirkwood Mountain Resort for Peak Pursuits participants will be $149 for a Queen/Queen, $164 for a studio, and $179 for a studio plus loft + taxes and fees`}</p>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
