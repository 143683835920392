import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import Collapsible from 'react-collapsible';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark}}>
      <div className="slant-bottom">
        <Hero
          title={'The Steeper the Climb, the Better the Views'}
          poweredByImage="/images/powered-by-ibis.png"
          //subtitle={'Where You Look is Where You Go'}
          backgroundImage={'/images/event-hero.jpg'}
          height={'full'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h1 className="oversized-text">{'No Shortcuts'}</h1>
              <p>{'It’s always steepest before the summit. But, if we just keep pedaling, we can turn life’s hardest things into life’s best things. Because all mountains feel big, all uphill climbs hurt, and all peak pursuits are hard:'}</p>
              <h1 className="stamp-text fancy-font">{'Do. It. Anyway'}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
