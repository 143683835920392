const Testimonials = [
  {
    author:`Kalotina M.`,
    quote:`We learned SO much about fueling, bike maintenance, and trail building in addition to the absolutely epic rides.
We got to play with some REALLY fun demo bikes and that was one of my favorite parts!

I will never forget these memories, met some awesome new riding friends and I’m leaving feeling rejuvenated at the soul level.. and I think that’s exactly what a retreat should be at its essence.
`
  },
  {
    author:`Michelle M`,
    quote:`Like most women, I underestimate my abilities but I am so proud I showed up at Peak Pursuits. I left feeling empowered and encouraged and knowing I can do more than I think I am capable of, especially with a village cheering you on and sharing in your joy! Trail digging with 30 women was so incredible, what an awesome experience to look back after two hours of work and be able to say, “Yeah, we just created this new trail”. So very cool and it was actually one of the most memorable moments of the weekend!

Thank you both for being inspired and working hard to pull this off!!!
`
  },
  {
    author:`Nicole P`,
    quote:`I went into my weekend with Peak Pursuits intimidated and left feeling empowered. The big ride day was the perfect amount of challenging for me and everyone there was nothing short of fun and encouraging. Being out in nature, riding with such strong and inspirational women had me stoked for days!`
  }
]

export default Testimonials;
