import React, { Component } from 'react';
import "./knockoutComp.scss";
import { Link } from "react-router-dom";
import { Parallax } from 'react-scroll-parallax';

export default class KnockoutComp extends Component {
  render() {
    return (
      <div className="knockout-comp" style={{backgroundColor:this.props.backgroundColor}}>
        <div className="knockout-layer-container">
          <Parallax translateY={[-5, 25]}>
            <div className="knockout-text" style={{backgroundImage:"url("+this.props.knockoutTextBackground+")"}}>
              {this.props.knockoutText}
            </div>
          </Parallax>
          <div className="knockout-text-overlay">
            <Parallax translateY={[-10, 15]}>
              <img className="full-width" src={this.props.knockoutOverlayImage} />
            </Parallax>
          </div>
        </div>
        <div className="knockout-content-container">
          <div className="gutter medium-max-width-container">
            <h2 style={{color:this.props.textColor}}>{this.props.title}</h2>
            {this.props.subtitle && <h3 style={{color:this.props.textColor, marginTop:80}}>{this.props.subtitle}</h3>}
            <p className="subheading" style={{color:this.props.textColor}}>{this.props.description}</p>
            <div className="stamp-text fancy-font">{this.props.cursiveText}</div>
            <Link to={this.props.link}>
              <div className="button" style={{color:this.props.buttonTextColor, backgroundColor:this.props.buttonBackgroundColor}}>
                {this.props.buttonText}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
