import React, { Component } from "react";
import { NavLink,Link } from "react-router-dom";
import "./header.scss";


export default class Header extends Component {
	constructor() {
      super();
      this.state = {
          scrollActive: 'hidden'
      };
  }
	showMenu = () =>{
		if(this.state.mobileMenuActive === 'active'){
			this.setState({mobileMenuActive: 'hidden'})
		} else{
		    this.setState({mobileMenuActive: 'active'})
		}

	}
	render() {
		return (
			<div>
				<div id="header" className="top-bar">
					<div className={this.state.scrollActive}>
						<div className="logo-container">
							<NavLink exact to="/" >
								<img alt="" className="logo" src="/images/logo.png" />
							</NavLink>
						</div>
						<nav>
							<div className="hide-for-small">
								<NavLink to="/event">Event</NavLink>
								<NavLink to="/story">Story</NavLink>
								<NavLink to="/sponsors">Sponsors</NavLink>
								<NavLink  style={{opacity:1}} to="/register">
									<div className="button header-cta">Register</div>
								</NavLink>
							</div>
							<div id="mobile-menu-icon" className="show-for-small" onClick={this.showMenu}>
								<img alt="" className="mobile-menu-icon-light full-width" src="/images/menu-light.png" />
								<img alt="" className="mobile-menu-icon-dark full-width" src="/images/menu-dark.png" />
							</div>
						</nav>
						<div className="top-bar-background"></div>
					</div>
					<div className="coupon-container"><Link to="/register">{'$50 off before 6/24 with code '}<b>{'EARLYBIRD50'}</b></Link></div>
				</div>
				<div id="mobile-menu" className={this.state.mobileMenuActive} style={{backgroundImage:"url(./images/topo-texture.png)"}}>
					<div className="top-bar">
						<div className="logo-container">
						</div>
						<nav>
							<div id="mobile-menu-icon" className="show-for-small" onClick={this.showMenu}>
								<img alt="" className="mobile-menu-icon-light full-width" src="/images/close-icon.png" />
							</div>
						</nav>
						<div className="coupon-container"><Link to="/register">{'$50 off before 6/24 with code '}<b>{'EARLYBIRD50'}</b></Link></div>
					</div>
					<div className="mobile-menu-content">
						<NavLink exact to="/" onClick={this.showMenu}>
							<img alt="" className="logo logo-light shadow" src="images/mobile-logo.png" />
						</NavLink>
						<NavLink to="/event" onClick={this.showMenu}>Event</NavLink>
						<NavLink to="/mission" onClick={this.showMenu}>Mission</NavLink>
						<NavLink to="/story" onClick={this.showMenu}>Story</NavLink>
						<NavLink to="/sponsors" onClick={this.showMenu}>Sponsors</NavLink>
						<NavLink to="/register" onClick={this.showMenu}>
							<div className="header-cta button">Register</div>
						</NavLink>
					</div>
				</div>
			</div>
		)
	}
}
