import React, { Component } from "react";
import BangerText from './../../components/bangerText';
import FeaturedCompanies from './../../components/featuredCompanies';
import VideoHero from './../../components/videoHero';
import Countdown from "./../../components/countdown";
import Footer from "./../../components/footer";
import SideBySideCardAndImage from "./../../components/sideBySideCardAndImage";
import EpicCardGrid from "./../../components/epicCardGrid";
import KnockoutComp from "./../../components/knockoutComp";
import ParallaxCollage1 from "./../../components/parallaxCollage1";
import ScrollHighlightText from "./../../components/scrollHighlightText";
import  './../../utils/analytics';
import {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import { Link } from "react-router-dom";

export default class Home extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div>
			<VideoHero
				subtitle="Women's Mountain Biking Retreat"
				title={['Gear Up for the Ultimate ',<br />, 'MTB Weekend!']}
				video="WEysq3a9qPo"
				height="full"
        verticalText='Kirkwood Mountain Resort'
        poweredByImage="/images/powered-by-ibis.png"
			/>
      <div className="countdown-section">
        <Countdown
          title="September 19-21, 2025"
          time="Sep 19, 2025 15:37:25"
          titleColor={Colors.white}
          textColor={Colors.white}
          backgroundColor={Colors.primary}/>
      </div>
      <div className="home-page-slant-shim countdown-section">
        <SideBySideCardAndImage
          title="A Women's Only Event"
          description="Join Peak Pursuits for a women’s mountain biking retreat based in the Sierras. Spend the weekend on adventure rides with big climbs and fun downhills, community, bike clinics, and wellness workshops at the base of the mountains at Kirkwood Mountain Resort. You can plan to connect with like-minded individuals while ripping around on some of the most scenic trails in the area."
          buttonText="Learn More"
          image="/images/crowd.jpg"
          link="/event"
          buttonTextColor={Colors.white}
          buttonBackgroundColor={Colors.tertiary}
          backgroundColor={Colors.primaryDark}
          backgroundTexture="/images/topo-texture.png"
          cardBackgroundColor={Colors.primary}
          titleColor={Colors.white}
          descriptionColor={Colors.white}
          verticalText='RIDER || ANNA P'
        />
      </div>
      <KnockoutComp
        knockoutText="peak"
        knockoutOverlayImage="/images/knockout-overlay.png"
        title="The Steeper the Climb, the Better the Views"
        knockoutTextBackground="/images/event-hero.jpg"
        description={`It’s always steepest before the summit. But, if we just keep pedaling, we can turn life’s hardest things into life’s best things.
Because all mountains feel big, all uphill climbs hurt, and all peak pursuits are hard:`}
        cursiveText={'Do. It. Anyway'}
        buttonText="learn more"
        buttonTextColor={Colors.white}
        buttonBackgroundColor={Colors.tertiary}
        backgroundColor={Colors.primary}
        link="/story"
        textColor={Colors.white}
        verticalText='RIDER || AMANDA P'
      />
      <div>
        <ScrollFadeIn>
          <div className="row" style={{color:Colors.white, minHeight:'90vh', alignItems:'center', backgroundColor:Colors.primary, backgroundImage:'url(/images/landscape.jpg)', backgroundSize:'cover', backgroundPosition:'center'}}>
            <div className="extra-large-padding centered-text small-max-width-container">
              <h1>{'Free to Roam: A Women\'s MTB Retreat'}</h1>
              <p className="cursive-text">{'Following a friend down a hard feature can make impossible feel possible. Let’s spend a girls only weekend riding bikes and learning from each other\'s climbs and crashes: the ones on and off the dirt. Bring your knee pads - this learning is happening in the field and on the best single-track around Kirkwood. With the support of the USFS and Kirkwood we are free to roam the wild spaces of the Sierras together. We may be biased, but Kirkwood is the best place to pedal and adventure. The ridgelines and meadows at the mountain will be the perfect backdrop for a weekend full of community and riding.'}</p>
              <Link to="/event">
                <div className="card-button button" style={{color:Colors.white, backgroundColor:Colors.tertiary}}>
                  {'Learn More'}
                </div>
              </Link>
            </div>
          </div>
        </ScrollFadeIn>
      </div>
      <EpicCardGrid
        title="Never Easier, just Faster"
        description="Adventure isn’t clean and you won’t be either after a weekend riding bikes with us. The pursuit of summits, the ones on and off the bike, can get a bit messy. But that’s where we come in, as a hand extended when you feel like quitting the climb. Community in the hard places: it’s the secret to the summit."
        buttonText="Our Story"
        link="/story"
        buttonTextColor={Colors.white}
        buttonBackgroundColor={Colors.tertiary}
        backgroundColor={Colors.primaryDark}
        backgroundTexture="/images/topo-texture.png"
        cardBackgroundColor={Colors.primary}
        titleColor={Colors.white}
        descriptionColor={Colors.white}
        parallaxImage1='/images/about-us-collage-1.jpg'
        parallaxImage2='/images/landscape-short.jpg'
        parallaxImage3='/images/about-us-collage-3.jpg'
        parallaxImage4='/images/about-us-collage-4.jpg'
      />
		</div>
    );
  }
}
