import React, { Component } from "react";
import Hero from './../../components/hero';
import { Link } from "react-router-dom";
import  './../../utils/analytics';
import  {Colors} from './../../utils/variables';
import ScrollFadeIn from './../../components/scrollFadeIn';
import Testimonials from './testimonials';
import Collapsible from 'react-collapsible';

export default class extends Component {
  componentDidMount = ()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
		<div className="light-grey-background" style={{backgroundColor:Colors.primaryDark}}>
      <div className="slant-bottom">
        <Hero
          title={'Free to Roam'}
          poweredByImage="/images/powered-by-ibis.png"
          //subtitle={'Where You Look is Where You Go'}
          backgroundImage={'/images/full-crew-hero.jpg'}
          height={'full'} /*full, half,threequarter*/
          tint={''}
        />
      </div>
      <div>
        <div className="gutter">
          <div className="medium-max-width-container lip shadow" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <div className="large-padding centered-text" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
              <h1 className="oversized-text">{'Dirt, sweat, and adventure'}</h1>
              <h2>{'September 19-21, 2025'}</h2>
              <p>{'This is what a weekend away on your bike, with like minded people is all about.  This weekend getaway will be an exhilarating blend of adventure and community on and off the trails with all of us doing hard things together and then celebrating our achievements over trail talks, happy hour, and cool raffles.  Participants can look forward to group rides, ranging from challenging uphills to thrilling downhill descents, ensuring a memorable experience for everyone.  Alongside the biking adventures, the weekend features a welcome party Friday evening, wellness workshops, bike skills clinics, a trail building workshop on Kirkwood trails, bike demos, delicious meals and an endless amount of time to connect with fellow riders. This is sure to be an amazing weekend that you won’t want to miss.'}</p>
              <div className="small-max-width-container">
                <h6 className="subheading">{`Please note:  At this time, this event is not intended for brand new/unskilled mtb riders due to technical trails and our small team’s limited resources to properly and safely support new mtb riders, but we welcome beginners looking to take the next step in their mountain biking journey and all intermediate and advanced riders. If you're unsure whether the event is the right fit for your current skill level, feel free to reach out, and we'd be happy to help you assess whether this is the right event for you.`}</h6>
              </div>
            </div>
          </div>
          <div className="medium-max-width-container shadow"  style={{marginTop:'5vh', marginBottom:'5vh', color:Colors.white, backgroundColor:Colors.primary}}>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/welcome.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position">
                    <h2>{"FRIDAY"}</h2>
                    <p><span className="subheading">{'Registration and Welcome Party'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row reverse" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/crowd.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"SATURDAY"}</h2>
                    <p><span className="subheading">{'Breakfast'}</span></p>
                    <p><span className="subheading">{'Group Rides'}</span></p>
                    <p><span className="subheading">{'Skills Clinics'}</span></p>
                    <p><span className="subheading">{'Bike Demos'}</span></p>
                    <p><span className="subheading">{'Wellness Workshops'}</span></p>
                    <p><span className="subheading">{'Trail Talks'}</span></p>
                    <p><span className="subheading">{'Happy Hour'}</span></p>
                    <p><span className="subheading">{'Raffle'}</span></p>
                    <p><span className="subheading">{'Dinner'}</span></p>

                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row alternating-grid-row" style={{backgroundImage:'url(/images/topo-texture.png)'}}>
                <div className="small-12 medium-6">
                  <img className="alternating-grid-image" alt="" src="images/square.png" style={{'backgroundImage':'url(images/trail-build.jpg)'}} />
                </div>
                <div className="small-12 medium-6 row">
                  <div className="alternating-grid-text center-position" style={{color:Colors.white}}>
                    <h2>{"SUNDAY"}</h2>
                    <p><span className="subheading">{'Breakfast'}</span></p>
                    <p><span className="subheading">{'Group Rides'}</span></p>
                    <p><span className="subheading">{'Skills Clinics '}</span></p>
                    <p><span className="subheading">{'Bike Demos'}</span></p>
                    <p><span className="subheading">{'Trail Building Party'}</span></p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
          </div>
        </div>
        <ScrollFadeIn>
          <div className="large-padding" style={{color:Colors.white, backgroundColor:Colors.primary}}>
            <h1 className="centered-text">{'FAQ'}</h1>
            <div className="medium-max-width-container shadow" style={{color:Colors.white, backgroundColor:Colors.primaryDark}}>
              <Collapsible trigger="What is included in my weekend registration?">
                <ul className="subheading">
                  <li>{'Welcome Party appetizers, a hearty breakfast and grab and go lunch options each day, and dinner Saturday evening.'}</li>
                  <li>{'Group rides, shuttles, bike patrol for medical support'}</li>
                  <li>{'Trail Building Workshop'}</li>
                  <li>{'Wellness Classes'}</li>
                  <li>{'Trail Talks'}</li>
                  <li>{'Ibis Bike Demos'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What is NOT included in my weekend registration?">
                <ul className="subheading">
                  <li>{'Lodging is not included.'}</li>
                  <li>{'Bike Skills Clinics are not included.'}</li>
                  <li>{'Alcohol is not included.'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What to bring?">
                <ul className="subheading">
                  <li>{'A freshly tuned, comfortable to you bike'}</li>
                  <li>{'backpack/hip pack'}</li>
                  <li>{'Helmet, full finger gloves, glasses'}</li>
                  <li>{'water, plenty of snacks, and lunch'}</li>
                  <li>{'spare tube and supplies to fix a flat'}</li>
                  <li>{'rain jacket'}</li>
                  <li>{'shirt and extra layers as needed'}</li>
                  <li>{'bike shoes/socks'}</li>
                  <li>{'bike pants/shorts, chamois'}</li>
                  <li>{'knee pads'}</li>
                  <li>{'post ride comfortable clothing'}</li>
                  <li>{'snacks, lots of snacks'}</li>
                  <li>{'food for lunches on the trail'}</li>
                  <li>{'Yoga Mat'}</li>
                </ul>
              </Collapsible>
              <Collapsible trigger="What should my skill level be, am I a good fit?">
                <p className="subheading">{'We plan to have a variety of group rides available all weekend for Beginners to Advanced riders. You will be encouraged to choose to ride whichever trail suits you best! '}</p>
              </Collapsible>
              <Collapsible trigger="Are your retreats e-bike friendly?">
                <p className="subheading">{'We love e-bikes!  Due to the nature of our retreat and to ensure a consistent experience for all participants, at this time we will not allow e-bikes at our retreats.'}</p>
              </Collapsible>
              <Collapsible trigger="What is your cancellation/refund policy?">
                <p className="subheading">{'Once you sign up we work extremely hard to make sure this will be an incredibly memorable and worthwhile weekend, therefore we will not honor cancellations.  We plan to ride rain, snow, or shine.  In the event, conditions are unsafe we will reschedule the event and your registration will be honored at a future event.'}</p>
              </Collapsible>
              <Collapsible trigger="How old do I have to be to attend this event?">
                <p className="subheading">{'Peak Pursuits welcomes all riders who are 18 years or older who identify as female to join our retreats.'}</p>
              </Collapsible>
              <Collapsible trigger="Do you offer any lodging options?">
                <p className="subheading">{'Yes! We encourage you to stay with us at Kirkwood! In order to receive the Peak Pursuits special group rate please call (855) 948-0694. This is a reservation line dedicated to making reservations for group guests. Guests can call every day from 8am-5pm MT. Please refer to the group code: '}<b>{'SKWPPB25'}</b>{' or the group name:  Peak Pursuits 25 when making your reservation. The nightly set rates for lodging at the Mountain Club at Kirkwood Mountain Resort for Peak Pursuits participants will be $149 for a Queen/Queen, $164 for a studio, and $179 for a studio plus loft + taxes and fees'}</p>
              </Collapsible>
              <Collapsible trigger="How can I be part of the amazing volunteer squad?">
                <p className="subheading">{'We’re so excited you want to join the fun! Volunteering with us is simple and super rewarding. Head over to the Forms and Documents page and fill out the volunteer application.  We can’t wait to hear about how awesome you are! '}</p>
              </Collapsible>
              <Collapsible trigger="Who do I contact if I have any further questions?">
                <p className="subheading">{'You can contact us! Please email '}<a style={{color:'#fff', fontWeight:'bold'}} href="mailto:peakpursuitsmtnbiking@gmail.com">{'peakpursuitsmtnbiking@gmail.com'}</a>{ ', and we’ll reply faster than a chipmunk dodging a mountain bike. (Okay, maybe not that fast, but close!)'}</p>
              </Collapsible>
            </div>
          </div>
        </ScrollFadeIn>
        <div>
          <ScrollFadeIn>
            <div className="shadow" style={{color:Colors.white, backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
              <div className="large-padding centered-text">
                <h1>{'Testimonials'}</h1>
                <div className="testimonial-row row" style={{alignItems: 'stretch'}}>
                  {Testimonials && Testimonials.map((testimonial) =>{
                    return(
                      <div className="small-12 medium-4">
                        <div className="small-padding" style={{height:'100%'}}>
                          <div className="testimonial-card medium-padding" style={{height:'100%'}}>
                            <p className="subheading">{testimonial.quote}</p>
                            <p><b>{testimonial.author}</b></p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </ScrollFadeIn>
        </div>
        <div className="slant-top large-padding centered-text" style={{color:Colors.white}}>
          <ScrollFadeIn>
            <h1>{'Safety'}</h1>
            <div className="small-max-width-container">
              <p className="subheading">{'Peak Pursuits Mountain Biking will prioritize your safety throughout our weekend together. Minimizing the risk of injuries and accidents will be of the utmost importance to Peak Pursuits Mountain Biking.  A few key points to remember:  always wear appropriate safety gear, perform pre-ride checks, know your limits, practice trail etiquette, and stay hydrated and fueled. With these precautions in place, we will hit the trails with confidence and peace of mind, ready to embrace the adventure that awaits. '}</p>
              <div className="small-padding" style={{paddingBottom:0}}>
                <h2 className="fancy-font">{'Happy riding!'}</h2>
              </div>
            </div>
          </ScrollFadeIn>
        </div>
        <div>
          <ScrollFadeIn>
            <div className="shadow" style={{color:Colors.white, backgroundColor:Colors.primary, backgroundImage:'url(/images/topo-texture.png)'}}>
              <div className="large-padding centered-text">
                <h1>{'Lets Do This Thing'}</h1>
                <p className="cursive-text subheading">{'Space is limited to 75 people, register today to save your spot'}</p>
                <Link to="/register">
                  <div className="card-button button" style={{color:Colors.white, backgroundColor:Colors.tertiary}}>
                    {'Register Today'}
                  </div>
                </Link>
              </div>
            </div>
          </ScrollFadeIn>
        </div>
      </div>
    </div>
    );
  }
}
